.ExtendButton{
    width: 16vw;
    margin-left: 30vw;
    margin-right: 2vw;
}

@media only screen and (max-width: 600px) {
	.Hidden{
        display: none;
    }
    
    .Center{
        display: flex;
        justify-content: center;
}
    .ExtendButton{
        width: 21vw;
        margin-left: 0px;
        margin-right: 0px
    }
}