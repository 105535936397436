.FormBody{
    align-items: center;
    color: #fff;
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    z-index: 1;
}

.LanguageCard{
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid hsla(0,0%,93%,.25);
    border-radius: 10px;
    color: black;
		width: 250px;
		height: 200px;
		border-radius: 10px;
		margin: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		cursor: pointer;
		user-select: none;
		font-size: 1.1em;
		padding: 0 20px;
		box-sizing: border-box;
}

button{
    
}

.ContainerMain {
	max-width: 100%;
	margin: auto;
	flex-direction: row;
    display: flex;
	justify-content: center;
	flex-wrap: wrap;
}


.QuestionHeader{
    text-align: center;
}

.LanguageCard:hover {
    background: hsla(0,0%,100%,.15);
    border: 2px solid #ff6f1a;
}
.LanguageCard:focus {
    background: hsla(0,0%,100%,.15);
    border: 2px solid #ff6f1a;
}



