.WelcomeCard {
	box-sizing: border-box;
	border-radius: 10px;
	border: 1px solid #ccc;
	padding: 70px 50px;
	display: flex;
	flex-direction: row;
	background-color: #FAF9F6;
	margin-bottom: 30px;
	margin-top: 20px;
}

.Title {
	font-size: 2em;
	color: rgb(50,50,50);
	font-weight: bold;
	margin-bottom: 10px;
}

.TextWrapper {
	flex-grow: 1;
}

.BtnWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.CoursesBtn {
	padding: 10px 15px;
	background-color: #FA42A2;
	border-radius: 3px;
	color: white;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	margin-left: 10px;
}

@media only screen and (max-width: 600px) {
	.WelcomeCard {
		padding: 20px 20px;
	}
}
