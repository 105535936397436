.ViewBox{
    background-color:white;
    /* color:black; */
    margin-bottom: 20px;
    margin-top: 50px;
    width: 80vw;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    overflow: scroll;
    /* margin-right: 0px; */
    height: fit-content+100px;
    border-radius: 10px;
    /* box-shadow: 1px 2px rgba(0, 0, 0, 0.1) */
    border: 2px solid rgba(0, 0, 0, 0.1)
}

.FlagCol{
    align-self: center;
    /* background-color: red; */
    overflow: scroll;
    /* padding-left: 50px; */
}

.Flag{
    height: 200px;
    width: 200px; 
    margin-left: 30px; 
    /* margin-top: 80px;  */
}

.LangData{
    /* align-self: center; */
    margin-right: 0px;
    margin-left: 1px;
    /* padding: 0px 0px 0px 0px; */
    font-family: Muli;
    /* ali */
    /* background-color: yellow; */
    color: rgb(242, 115, 18);
    padding-right: 0px;
    width: fit-content;
    text-align: right;
    
}

.hr{
    /* border-color: yellow; */
    background-color: grey;
    margin-left: 20px;
    margin-right: 20px;
    /* height: 20px; */
}

.Name{
    width: 100%;
    /* background-color: pink; */
    /* font-size: ; */
}


.SecondRow{
    align-items: right;
    font-size: 20px;
    /* float: right; */
    color: black;
    margin-right: 30px;
    /* background-color: red; */
}

.ThirdRow{
    /* background-color: red; */
    align-items: right;
    font-size: 20px;
    color: black;
    margin-right: 30px;
    /* float: right; */
}

.SecondCol{
    display: flex;
    white-space: nowrap;    
    /* text-align: right; */
    
}


.ThirdCol{
    white-space: nowrap;
    display: flex;
}

.IconText1{
    margin-right: 20px;
    text-align: right;
    /* margin-left: 130px; */
    width: 100%;
}
.IconText2{
    margin-right: 15px;
    /* margin-left: 130px; */
    width: 100%;
}

.NameRow{
    /* width: 100%;  */
    /* padding-left: 70px; */
    text-align: right;
    margin-right: 30px;
}


@font-face{
    font-family: Muli;
    src:url(Mulish/Mulish-VariableFont_wght.ttf)
}

@font-face{
    font-family: Muli;
    src:url(Mulish/static/Mulish-Bold.ttf);
    font-weight: bold;
}
@font-face{
    font-family: Muli;
    src:url(Mulish/static/Mulish-ExtraBold.ttf);
    font-weight: 800;
}

@font-face{
    font-family: Muli;
    src:url(Mulish/static/Mulish-SemiBold.ttf);
    font-weight: 500;
}


@media only screen and (max-width: 789px) {
    .NameRow{
        /* margin: auto auto auto auto; */
        /* font-size: 7vw; */
        /* margin-right: 30px; */
        font-family: Muli;
        /* text-align: center; */
        padding-left: 0px;
    }
}


@media only screen and (max-width: 691px) {

     .Flag {
        margin: 0px;
        display: block;
        width: 150px;
        height: 150px;
        position: relative;
        margin: auto;
        
        /* height: auto; */
    }

    .NameRow{
        margin: auto auto auto auto;
        /* font-size: 30pt; */
        font-family: Muli;
        font-size: 50px;
        text-align: center;
        padding-left: 0px;
    }

    .ViewBox{
        position: relative;
        /* left: 50%; */
        width: 380px;
        height: auto;
        /* display: flex; */
        overflow: scroll;
    }

    .ThirdRow{
        align-items: center;
        text-align: center;
    }

    .SecondRow{
        align-items: center;
        text-align: center;
    }
    .IconText2{
        text-align: center;
        align-self: center;
    }
    .IconText1{
        text-align: center;
        align-self: center;
    }
  }
