

.Loader{
    position: relative;
    top: 70px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* background: linear-gradient( rgba(130, 95, 182, 0.9215), rgba(245, 122, 26, 0.97)); */
    background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
    animation: animate 0.5s linear infinite;
    align-items: center;
    justify-content: center;
    display: flex;
}

@keyframes animate{
    
    0%{
        transform: rotate(0deg);

    }100%{
        transform: rotate(360deg);
    }



}
.Loader span{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
    /* background: linear-gradient( rgba(130, 95, 182, 0.9215), rgba(245, 122, 26, 0.97)); */
}

.Loader span:nth-child(1){
    filter: blur(5px);
}
.Loader span:nth-child(2){
    filter: blur(10px);
}
.Loader span:nth-child(3){
    filter: blur(25px);
}
.Loader span:nth-child(4){
    filter: blur(50px);
}
.Loader::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    /* background: #240229; */
    background: #550561;
    /* background: #f57a1a; */
    /* linear-gradient(180deg, rgba(130, 95, 182, 0.9215) 0%, rgba(245, 122, 26, 0.97) 100%) */
    /* background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0); */
    /* background:  linear-gradient(black, white); */
    border-radius: 50%;
} 


















