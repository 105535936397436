.LanguageCard{
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid hsla(0,0%,93%,.25);
    border-radius: 10px;
    color: #fff;
		width: 250px;
		height: 200px;
		border-radius: 10px;
		margin: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		cursor: pointer;
		user-select: none;
		font-size: 1.1em;
		padding: 0 20px;
		box-sizing: border-box;
		/* ................ */
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}


.LanguageCard:hover {
    background: hsla(0,0%,100%,.15);
    border: 2px solid hsla(0,0%,100%,.5);
	/* ................... */

}

.ScaleAnimation{
	
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.ScaleAnimation:hover{
	-webkit-transform: scale(1.05, 1.05);
	transform: scale(1.05, 1.05);
}

.ScaleAnimation::after {
	border-radius: 5px;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	opacity: 0;
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }


  .ScaleAnimation:hover::after {
    opacity: 1;
} 
/* 
.box {
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
  
  border-radius: 5px;
  
}

.box::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box:hover {

}

.box:hover::after {
    opacity: 1;
}
 */

.LoadingBox {
	height: 200px;
	animation-name: shine;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-direction: alternate;
}

.NextUpLabel {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-size: 0.6em;
	font-weight: 600;
	margin-bottom: 2px;
}

.RightColumn {
	max-width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
