.animate{
    /* background-color: blue; */
    
    /* width: 100vw; */
    margin: 0px 0px 0px 0px;
    padding-left: 15px;
    position: relative;
    animation: fade-in 2s linear forwards;

}


.view {
    
    /* overflow: hidden; */
    /* overflow: visible; */
    /* animation: fade-in 2s linear; */
    /* padding-left: 5cm; */

}



  @keyframes fade-in {
    0% {
        opacity: 0;
        height: 0vh;

    }50%{
        opacity: 0.5;
        height: 50vh;
        /* width: 50%; */
    }
    100% {
        opacity: 1;
        height: 100vh;
        /* width: 50%; */
    }
  }

.heading1   {    
    margin-bottom: 5vh;
    font-family: Muli;
    font-weight: bolder;

}

h1 {
    margin: 0 auto;
    margin-top: 2vh;
}

.headingText{
    text-align: center;

}

.heading2   {
    margin-bottom: 1vh;
    font-family: Muli;
    font-weight: 500;
    text-align: center;
}

.heading3{
    margin-bottom: 1.5vh;
    font-family: Muli;
    font-weight: bold;
}

.heading4{
    margin-bottom: 1.5vh;
    font-family: Muli;
    font-weight: bold;
    /* animation: slide 5s linear forwards; */
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
}

.normalText{
    margin-bottom: 2vh;
    font-family: Muli;
    font-size: 20px;
}


.split {
    padding-left: 0%;
    padding-bottom: 2vh;
    text-align: left;
    font-family: Muli;
}

.underlined {
    border-bottom: 0.6vh solid aqua;
    padding-left: 1vw;
    padding-right: 1vw;
}

.overlined {
    border-top: 0.6vh solid aqua;
    padding-bottom: 1vh;
    padding-top: 1vh;
    font-family: Muli;
    font-weight: bolder;
}

.padding {
    margin-top: 1vh;
    padding-bottom: 0vh;
    padding-top: 0.5vh;
}

.text {
    padding-top: 1vh;
    padding-left: 0%;
    text-align: left;
    color: black;
    font-family: Muli;
    font-size: 16pt;
    /* animation: nslide 3s linear forwards; */
}

@keyframes nslide {
    0% {
        width: 0px;
        font-size: 0pt;
    }
    60%{
        width: 0px;
        font-size: 0pt;
    }
    95% {
        width: max-content;
        font-size: 16pt;
    }
  }

  .textAnimation{

    /* animation: slide 3s linear forwards; */
    text-align: left;
    overflow: hidden;
    
  }



.Button{
    width: inherit;
    height: 4vh;
    margin: auto;
    margin-top: 8vh;
    margin-bottom: 10vh;
    line-height: 50%;
    font-family: Muli;
    font-size: 15pt;
}




.image{
    /* float: left; */
    margin-bottom: 3vh;
    /* animation: slide 3.8s linear forwards; */
    
}
/* className={styles.test}
 .test{
    animation: tester 3.8s linear forwards;
    animation-delay: 3.8s;
    white-space: nowrap;
    overflow: hidden;
    
} */



@keyframes slide {
    0% {
        overflow: hidden;
        white-space: nowrap;
        width: 0%;
    }
    50%{
        overflow: hidden;
        white-space: nowrap;
        width: 0%;
    }
    99% {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }100%{
        overflow: hidden;
        white-space: pre-wrap;
        width: 100%
    }
  }


.splitImage{
    float: left;
    padding-right: 2vw;

}

.midFontL{
    font-weight: bold;
    text-align: left;
    font-size: 40px;
}
.midFontS{
    text-align: left;
    font-size: 30px;
}



/*  
 @media (max-width: 600px) {

    .midFontL { 
       font-size: 18px; 
    }
 
 } */

 @font-face{
    font-family: Muli;
    src:url(Mulish/Mulish-VariableFont_wght.ttf)
}

@font-face{
    font-family: Muli;
    src:url(Mulish/static/Mulish-Bold.ttf);
    font-weight: bold;
}
@font-face{
    font-family: Muli;
    src:url(Mulish/static/Mulish-ExtraBold.ttf);
    font-weight: 800;
}

@font-face{
    font-family: Muli;
    src:url(Mulish/static/Mulish-SemiBold.ttf);
    font-weight: 500;
}