.Content {
	box-sizing: border-box;
	padding: 62px 0;
	width: 100%;
	min-height: 100%;

	background-position: 0% 100%;
	background-size: 20% auto;
	background-repeat: no-repeat;
	background: linear-gradient(180deg, rgba(2, 2, 2, 0.922) 0%, rgba(0, 0, 0, 0.97) 100%);
}

.WhiteBox {
	margin: 50px auto;
	margin-bottom: 70vh;
	width: 380px;
	max-width: 80%;
	background: linear-gradient(180deg, rgba(2, 2, 2, 0.922) 0%, rgba(0, 0, 0, 0.97) 100%);
	border-radius: 10px;
	padding: 20px;
}

.LoginTitle {
	font-weight: bold;
	font-size: 1.5em;
	color: #3E3E3E;
	margin-bottom: 20px;
}

.Label {
	font-weight: bold;
	font-size: 0.9em;
	color: #3E3E3E;
	margin-top: 10px;
	margin-bottom: 5px;
}

.Input {
	width: 100%;
	font-size: 16px;
	height: 44px;
	border-radius: 6px;
	border: 1px solid #e4e4e4;
	box-sizing: border-box;
	padding: 11px 18px;
	color: #3E3E3E;
}

.LoginButton {
	margin: 15px 0;
	background-color: rgba(245, 122, 26, 0.97) ;
	color: white;
	border: 1px solid white;
	height: 47px;
	border-radius: 6px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	user-select: none;
}

.ForgotPassword{
	text-align: right !important;
	font-size: 0.6em
}

.ErrorMessage {
	color: red;
	font-size: 0.8em;
}

.Error {
	border: 1px solid rgba(255,0,0,0.7);
}
