.PageHead {
	padding-top: 62px;
	background-color: white;
	height: 74px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	border-bottom: solid 1px #e6e6e6;
}

.PageHeadRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 960px;
	max-width: 960px;
	margin: 0 30px;
}

.Title {
	font-size: 25px;
	font-weight: bold;
	color: #3e3e3e;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.CreateButton {
	text-decoration: none;
	background-color: #08b60b;
	border: 1px solid #069108;
	color: #fff;
	padding: 7px 20px;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.16), inset 0px -2px 7px 0px rgba(0, 0, 0, 0.1),
		inset 0px 2px 0px 0px rgba(255, 255, 255, 0.3);
	font-size: 0.9em;
	user-select: none;
}

.body{
	position: "relative";
	flex-wrap: "wrap";
	color:"black";
	max-width: 100%;
		overflow-x: hidden;
			overflow-y: scroll;
	margin-top: 0%;

	height:100vh;

	background: linear-gradient(180deg, rgba(2, 2, 2, 0.922) 0%, rgba(0, 0, 0, 0.97) 100%);
}

.CreateButton:hover {
	background-color: #10c010;
}

.Content {
	box-sizing: border-box;
	padding: 26px 0;
	min-width: 100%;
	background: transparent;
}

.ContainerMain {
	width: 960px;
	max-width: 100%;
	margin: auto;
	display: flex;

	flex-direction: row;
}

.RightColumn {
	height: 100%;
	width: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.LanguageCardPlaceHolder {
	width: 200px;
	height: 100px;
	box-shadow: rgba(0, 0, 0, 0.08) 0 1px 2px;
	border: 1px solid #e4e4e4;
	border-radius: 10px;
	margin: 20px;
	box-sizing: border-box;
	animation-name: shine;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-direction: alternate;
}

.chooseLanguageList {
	text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 1065px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.LanguageCard{
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid hsla(0,0%,93%,.25);
    border-radius: 10px;
    color: #fff;
	width: 250px;
	height: 200px;
	border-radius: 10px;
	margin: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	user-select: none;
	font-size: 1.1em;
	padding: 0 20px;
	box-sizing: border-box;
}

.SearchBar{
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid hsla(0,0%,93%,.25);
    border-radius: 10px;
    color: #fff;
	width: 830px;
	height: 40px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	user-select: none;
	font-size: 1.1em;
	padding: 0 20px;
	box-sizing: border-box;
	margin: auto;
	-webkit-user-select: text;
	user-select: text;
}
.SearchBar:hover {
    background: hsla(0,0%,100%,.15);
    border: 2px solid hsla(0,0%,100%,.5);
}
.LanguageCard:hover {
    background: hsla(0,0%,100%,.15);
    border: 2px solid hsla(0,0%,100%,.5);
}

.flagIconLanguageTitle{
    font-size: 20px;
    margin-bottom: 10px;
}

.flagIconLanguageStats{
    font-size: 14px;
}
.spanish-span{ background-position: 0 -32.19512px;}
.french-span{ background-position: 0 -64.39024px;}
.german-span{ background-position: 0 -96.58537px;}
.japanese-span {     background-position: 0 -128.78049px;}
@keyframes shine {
	from {
		transition: none;
	}
	to {
		background-color: #dedede;
		transition: all 0.3s ease-out;
	}
}

@media only screen and (max-width: 600px) {
	.Content {
		padding-top: 10px;
	}
	.CourseCard {
		width: 80%;
		margin: 10px 20px;
	}
}

.ScaleAnimation{
	
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ScaleAnimation:hover{
	-webkit-transform: scale(1.05, 1.05);
	transform: scale(1.05, 1.05);
}

.ScaleAnimation::after {
	border-radius: 5px;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	opacity: 0;
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

.ScaleAnimation:hover::after {
    opacity: 1;
}

::placeholder {
	color: hsla(0,0%,93%,.25);
}

input, textarea {
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
  }

@media only screen and (max-width: 859px) {
	.SearchBar {
		width: 550px;
	}
  }

@media only screen and (max-width: 579px) {
	.SearchBar {
		width: 250px;
	}
}