.body{
	position: "relative";
	flex-wrap: "wrap";
	color:"black";
	max-width: 100%;
		overflow-x: hidden;
	margin-top: 0%;


	height:100vh;
	background-color:white;
}
.PageHead {
	padding-top: 62px;
	background-color: white;
	height: 74px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	border-bottom: solid 1px #e6e6e6;
}

.PageHeadRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 960px;
	max-width: 960px;
	margin: 0 30px;
}

.Title {
	font-size: 25px;
	font-weight: bold;
	color: #3e3e3e;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.CreateButton {
	text-decoration: none;
	background-color: #08b60b;
	border: 1px solid #069108;
	color: #fff;
	padding: 7px 20px;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.16), inset 0px -2px 7px 0px rgba(0, 0, 0, 0.1),
		inset 0px 2px 0px 0px rgba(255, 255, 255, 0.3);
	font-size: 0.9em;
	user-select: none;
}

.CreateButton:hover {
	background-color: #10c010;
}

.Content {
	box-sizing: border-box;
	padding: 26px 0;
	min-width: 100%;
	background: transparent;
}

.ContainerMain {
	width: 960px;
	max-width: 100%;
	height: 100%;
	margin: auto;
	display: flex;
	flex-direction: row;
}

.RightColumn {
	height: 100%;
	width: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.CourseCard {
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid hsla(0,0%,93%,.25);
	border-radius: 10px;
	color: black;
	font-weight: bold;
	width: 400px;
	height: 150px;
	border-radius: 10px;
	margin: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	user-select: none;
	font-size: 1.1em;
	padding: 0 20px;
	background-color: #FFF4E0;
	border-radius: 20px;
	box-sizing: border-box;
}
.WotdCard {
width:400px;
height:200px;
border-radius: 50px;
background-color:#ade0ff
;
font-size: 16px;
}

.CourseCard:hover {
	background: hsla(0,0%,100%,.15);
	border: 2px solid hsla(0,0%,100%,.5);
}

.CourseCardPlaceHolder {
	width: 200px;
	height: 100px;

	border: 1px solid #e4e4e4;
	border-radius: 10px;
	margin: 20px;
	box-sizing: border-box;

	animation-name: shine;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-direction: alternate;
}

@keyframes shine {
	from {
		transition: none;
	}
	to {
		background-color: #dedede;
		transition: all 0.3s ease-out;
	}
}

@media only screen and (max-width: 600px) {
	.Content {
		padding-top: 10px;
	}
	.CourseCard {
		width: 80%;
		margin: 10px 20px;
	}
}
