
.Body{
	text-align: center;
	min-height: "100vh";
  position: "relative";
	max-width: 100%;
		 overflow-x: hidden;
}
.FirstHalf {
	position: relative;
	flex-wrap: "wrap";
	color:"black";
	max-width: 100%;
		overflow-x: hidden;
	margin-top: 0%;
	overflow-y: hidden;

	/* height:100vh; */

	background: linear-gradient(180deg, rgba(2, 2, 2, 0.922) 0%, rgba(0, 0, 0, 0.97) 100%);
}

.TitleColor{
	color:white;
}
.FirstHalfContent {

	color:white;

	margin-left: 2vw;

	margin-top: 15vw;

}
.StartLearning{
	margin-bottom: 10vh;

}
.Title {

font-size:20vw;
	margin-left: 28vw;

}

.FirstHalfRow2 {
	color:rgb(0, 0, 0);
	color:rgb(0, 0, 0);
  white-space: nowrap;
	margin-left:40%;
	margin-bottom: 10vh;

}
.SecondHalf {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	margin-top: 10vh;



	background-color:white;
}


WhiteBox {
	margin: 50px auto;
	margin-bottom: 70vh;
	width: 380px;
	max-width: 80%;
	background: linear-gradient(180deg, rgba(2, 2, 2, 0.922) 0%, rgba(0, 0, 0, 0.97) 100%);
	border-radius: 10px;
	padding: 20px;
}

.LoginTitle {
	font-weight: bold;
	font-size: 1.5em;
	color: #3E3E3E;
	margin-bottom: 20px;
}

.Label {
	font-weight: bold;
	font-size: 0.9em;
	color: #3E3E3E;
	margin-top: 10px;
	margin-bottom: 5px;
}

.Input {
	width: 100%;
	font-size: 16px;
	height: 44px;
	border-radius: 6px;
	border: 1px solid #e4e4e4;
	box-sizing: border-box;
	padding: 11px 18px;
	color: #3E3E3E;
}

.LoginButton {
	margin: 15px 0;
	background-color: rgba(245, 122, 26, 0.97) ;
	color: white;
	border: 1px solid white;
	height: 47px;
	border-radius: 6px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	user-select: none;
}

.ForgotPassword{
	text-align: right !important;
	font-size: 0.6em
}

.ErrorMessage {
	color: red;
	font-size: 0.8em;
}

.Error {
	border: 1px solid rgba(255,0,0,0.7);
}

.LanguageContainer{
	background: rgba(245, 122, 26, 0.97);
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;

}

.LanguageContainer2{
	display: flex;
	height: 100px;
	width: 1004px;
}

.LanguageNav{
	margin: 17px auto;
	max-width: 924px;
	position: relative;
	white-space: nowrap;
}

.LeftArrow{
	cursor: pointer;
	color:white;
    opacity: .5;
    position: absolute;
    top: 10px;
	background-position: -615px -70px;
	height: 80px;
	width: 40px;

	background-image: url(img/left-arrow-line-symbol.svg);
    display: inline-block;
    vertical-align: middle;
	left: -40px;

}



.RightArrow{
	right: -40px;
color:white;
	cursor: pointer;
    opacity: .5;
    position: absolute;
    top: 10px;
	background-position: -615px -70px;
    height: 80px;
    width: 40px;

    display: inline-block;
    vertical-align: middle;
	right: -40px;
}

.LanguageContainer3{
	margin-top: 5px;
    overflow: hidden;
    width: 100%;

}

ul{
	list-style: none;
    margin-top: 10px;
    padding: 0;
	display: inline-block;

}

.LanguageList{
	transition: transform .5s ease-in-out;
    width: 900px;
	display: inline-block;
	vertical-align: middle;


}

.LanguageListEl{
	display: inline-block;
    min-width: 130px;
		margin-right: 10px;

}

.GhanaFlag {
    margin-right: 6px;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/Flag-Ghana.jpeg);
	background-repeat: no-repeat;
	border-radius: 10px
}

.TNj94{
	align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.NigeriaFlag{
	margin-right: 6px;
    transform-origin: 0 0;
	background-size: 40px 30px;
    display: inline-block;
    height: 30px;
    width: 40px;
	background-image: url(img/nigeria-flag-png-large.png);
	background-repeat: no-repeat;
	border-radius: 10px

}

.KenyaFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/Flag-Kenya.jpeg);
	background-repeat: no-repeat;
	border-radius: 10px

}
.EthiopiaFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/ethiopia.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.EgyptFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/egypt.png);
	background-repeat: no-repeat;
	border-radius: 10px
}
.BurkinaFasoFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/BurkinaFaso.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.AlgeriaFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/algeria.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.CongoFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/congo.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.HaitiFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/haiti.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.MalawiFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/malawi.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.SenegalFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/senegalflag.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.SomaliaFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/somaliaflag.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.MaliFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/mali.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.GuineaFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/guinea.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.EgyptFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/egypt.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.SouthAfricaFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/southafrica.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.ZimbabweFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/zimbabweflag.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
.RwandaFlag{
	margin-right: 6px;
    transform-origin: 0 0;
		background-size: 40px 30px;
	    display: inline-block;
	    height: 30px;
	    width: 40px;
	background-image: url(img/rwandaflag.png);
	background-repeat: no-repeat;
	border-radius: 10px

}
@media screen and (max-width: 928px) {
    .LanguageContainer{
		display: none;
	}
}
