
.Body{
	text-align: center;
	min-height: 95vh;
  position: relative;
	max-width: 100%;
		 overflow-x: hidden;

		 	background: linear-gradient(180deg, rgba(2, 2, 2, 0.922) 0%, rgba(0, 0, 0, 0.97) 100%);
}
.PageHead {
	padding-top: 62px;
	background-color: white;
	height: 74px;
	display: flex;
	justify-content: center;
	border-bottom: solid 1px #e6e6e6;
}

.PageHeadRow {
	height: 100%;
	width: 960px;
	max-width: 960px;
	margin: 0 30px;
}

.Title {
	font-size: 25px;
	font-weight: bold;
	color: #3e3e3e;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.Content {
	box-sizing: border-box;
	padding: 26px 0;
	padding-top: 80px;
	width: 100%;
	background: transparent;
}

.ContainerMain {
	max-width: 960px;
	height: 100%;
	margin: auto;
	padding: 0 10px;
	display: flex;
	flex-direction: row;
}

.RightColumn {
	height: 100%;
	width: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
	.Content {
		padding-top: 80px;
	}
	.ContainerMain {
		flex-direction: column;
		justify-content: center;
		align-content: center;
	}
	.RightColumn {
		margin: 10px;
	}
}
