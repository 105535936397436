.LeftColumn {
	border-radius: 5px;
	box-sizing: border-box;
	height: 100%;
	width: 245px;
}

@media only screen and (max-width: 600px) {
	.LeftColumn {
		margin: 0 auto;
	}
}