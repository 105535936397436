.Profile {
	border-radius: 5px;
	padding-top: 17px;
}

.ProfileHeader {
	border-radius: 5px 5px 0 0;
	background-position: 50% 35px;
	background-size: 250px;
	background-repeat: no-repeat;
	background-color:#8f59cc;
	height: 190px;
	position: relative;
}

.ImageWrapper {
	padding: 0 20px;
	margin-top: 10px;
	width: 100%;
	max-height: 100px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	box-sizing: border-box;
}
.btn:focus {
    outline: none !important;
    outline-offset: none !important;
}

.ProfileImage {

	border-radius: 50%;
	width: 99px;
	height: 99px;
}

.UserName {
	padding-top: 6px;
	color: white;
	width: 100%;
	text-align: center;
	font-weight: bold;
	font-size: 1.1em;
	min-height: 40px;
}

.Level {
	color: white;
	width: 100%;
	text-align: center;
	font-size: 0.9em;
	min-height: 1.2em;
}

.ProfileStats {
	background-color: white;
	padding: 15px 5px;
	display: flex;
	flex-direction: row;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.StatsWrapper {
	flex: 50%;
	text-align: center;
}

.StatsSeparator {
	width: 1px;
	height: 35px;
	background: rgba(0, 0, 0, 0.15);
	margin: 0 5px;
}

.StatsNumber {
	font-weight: bold;
	font-size: 18px;
	color: #1fa3e8;
	line-height: 1.11;
}

.StatsLabel {
	margin-top: 2px;
	font-size: 12px;
	line-height: 1.67;
	color: #3e3e3e;
}
