.Footer{
	text-align: center;
	max-height: "10vh";
  position: "relative";
	max-width: 100%;
}

.Body{
	text-align: center;
	min-height: "100vh";
  position: "relative";
	max-width: 100%;
		 overflow-x: hidden;
}
.FirstHalf {
	position: "relative";
	flex-wrap: "wrap";
	color:"black";
	max-width: 100%;
		overflow-x: hidden;
	margin-top: 0%;
overflow-y: hidden;

	height:100vh;

	background: linear-gradient(180deg, rgba(2, 2, 2, 0.922) 0%, rgba(0, 0, 0, 0.97) 100%);
}
