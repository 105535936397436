.SurveyBody{
    align-items: center;
    display: flex;
    justify-content: center;
    left: 0;
    padding-top: 52px;
    right: 0;
    top: 0;
    color: black;
    overflow: visible;
    width: auto;
}


.Body{
    width: 60%;
    overflow: visible;
    width: auto;
}
