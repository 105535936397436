.Content {
    padding-top: 130px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    text-align: center;
    color: black;
    font-size: 2em;
    font-weight: bold;
}

.NextButton {
  
}

.NextButton:hover {
    background-color: rgb(139, 197, 219);
}

.BtnText {
    flex-grow: 1;
    text-align: right;
    padding-right: 10px;
}

.RightArrow {
    flex-grow: 1;
    width: 25px;
    height: 25px;
    background-image: url('../../../assets/icons/right-arrow.svg');
    background-repeat: no-repeat;
    background-position: 0% 100%;
}
