.Content {
    margin: auto;
    padding: 0 15px;
    max-width: 600px;
    padding-top: 63px;
    display: flex;
    flex-direction: row;
      color: #3E3E3E;
}
.soundIcon{
  cursor: pointer;
  display: flex;
  justify-content: center;
  width:50px;
  height:50px;
  margin-top:-7px;
  border-radius: 10px;
  background-color: #e5e5e5;
  margin-left: 500px;

}

.soundIcon:hover {
    background-color: rgb(139, 197, 219);
}

.playAudio{
  margin-top: 20px;
  background: transparent;
   border: none !important;
   font-size:0;
}
.WordCard{
  border-style: solid;
  border-color: #e5e5e5;

margin-top:50px;
  width:200px;
  height:200px;
  border-radius: 10px;
  box-shadow: 30px;
}
.soundIconImg{
  width:15px;
  height:15px;
  margin-top:15px;

}
.Main {
    flex-grow: 1;
    padding-top: 30px;
}

.Word {
  font-weight: bold;
  font-size: 20px;
  color: #3E3E3E;
  margin-top:-190px;
  margin-left:220px;

}
.Description{

  font-size: 20px;
  color: #3E3E3E;

  margin-left:220px;

}
.orangeLine{
  width:200px;
  height:2px;
    border-radius: 2px;
      margin-left:220px;
  background-color: #ff6f1a;

}



.RightColumn {
  width:100px;
    padding-top: 30px;
    padding-left: 70px;
}

.NextButton {
  text-align: center;
    height: 60px;
    width: 500px;
margin-top: 200px;
    color: white;
    background-color: #ff6f1a;
font-size:30px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
}

.NextButton:hover {
    background-color: rgb(139, 197, 219);
}

.RightArrow {
    width: 100%;
    height: 25px;
    background-image: url('../../../assets/icons/right-arrow.svg');
    background-repeat: no-repeat;
    background-position: 0% 100%;
    margin: 10px 0;
    margin-left: 7px;
}

.challengeSubbox{
        display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
}


.challengeTranslatePrompt {
    -ms-flex-positive: 0;
    flex-grow: 0;
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: left;
    width: 100%;
}
.markMeaningContent {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-pack: center;
    justify-content: center;
}
.m-m-l {
    margin: 0;
    padding: 0;
}
.m-m-li {
    margin-bottom: 10px;
}
.m-m-label {
    cursor: pointer;
    font-size: 19px;
    line-height: 27px;
    position: relative;
    text-align: center;
    width: 100%;
    color: #4b4b4b;
    border-width: 2px 2px 4px;
    border-color: #e5e5e5;
    padding: 12px 16px;
    border-radius: 16px;
    border-style: solid;
    transform: translateZ(0);
    transition: filter .2s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    box-sizing: border-box;

}
.m-m-number {
    border: 2px solid #e5e5e5;
    border-radius: 8px;
    color: #777;
    /* display: none; */
    font-size: 15px;
    font-weight: 700;
    /* upped to mac */
    max-height: 30px;
    line-height: 12px;
    margin-right: 0;
    margin-top: -3px;
    padding: 8px;
    /* upped to max */
    max-width: 30px;
        flex-shrink: 0;
    float: left;
}

.m-m-label:hover{
    color: #1cb0f6;
    border-color: #1bb0f6;
    background-color: #ddf4ff;
    cursor: pointer;

}
.m-m-label:hover .m-m-number {
    border-color: #1cb0f6;
    color: #1caff6;
}

 /* .m-m-label input[type="radio"]:checked {
    color: #1cb0f6;
    border-color: #1bb0f6;
    background-color: #ddf4ff;
} */

/* THIS IS THE CLOSEST I CAN GET 7/9 */
/* input[type="radio"]:checked~m-m-label{
    color: #1cb0f6;
    border-color: #1bb0f6;
    background-color: #ddf4ff;
} */
.m-m-label:focus {
     color: #1cb0f6;
    border-color: #1bb0f6;
    background-color: #ddf4ff;
}

.checked {
    color: #1cb0f6;
    border-color: #1bb0f6;
    background-color: #ddf4ff;
}

.m-m-label[type="radio"][checked] {
    color: #1cb0f6;
    border-color: #1bb0f6;
    background-color: #ddf4ff;
}

.m-m-input {
        position: absolute;
    visibility: hidden;
        cursor: pointer;
    opacity: 0;
}
.m-m-choice-div {
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: normal;
        font-weight: 400
}
.challenge {
    transform: translateZ(0);
    width: 100%;
    margin: auto;
    -ms-flex-align: initial;
    align-items: initial;
    -ms-flex-pack: initial;
    justify-content: initial
}
.challengeHeader {
        font-size: 35px;
    line-height: 24px;
        margin: 0;
    text-align: left;
    width: 100%;
}

.challengeSentence {

    font: 500 17px/20px sans-serif;
    line-height: 39px;
}
.challengeTranslate {
  /* height: 66vh; */
      max-height: 350px;
      max-width: 600px;
      overflow: visible;
}

@media (min-width: 700px) {
    .m-m-.number {
        display: inline-block;
    }
    .m-m-choice-div {
            padding-left: 12px;
    padding-right: 30px;
    }
}
