.body{
	position: relative;
	flex-wrap: wrap;
	/* color: black; */
	margin: auto;
	margin-top: 80px;
	margin-bottom: 0%;
	padding-bottom: 80px;
	width: 80vw;
	height: fit-content;
	padding-top: 80px;
	padding-left: 120px;
	padding-right: 120px;
	/* max-width: 100%; */
	border-radius: 10px;
	border: 3px solid rgba(0, 0, 0, 0.1);
	/* background-color:red; */
	/* color: blue; */
	overflow-x: hidden;
	/* margin-top: 0%; */
	background-color:white;
}
.NameText{
	font-family: Muli;
	font-size: 30pt;
	font-weight: 500;

}

.PageHead {
	padding-top: 62px;
	background-color: white;
	height: 74px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	border-bottom: solid 1px #e6e6e6;
}

.Circle{
	height: 120px;
	width: 120px;
	border-radius: 50%;
	border: dashed grey;
	margin-right: 80px;
	/* margin-bottom: 80px; */
	
}
.NameCol{
	color: black;
	font-family: Muli;
	font-size: inherit;
}



.ViewBox{
	/* background-color:red; */
    /* color:black; */
    /* margin-bottom: 20px;
    margin-top: 50px;
    width: 98vw;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    overflow: scroll; */
    /* margin-right: 0px; */
	overflow: hidden;
	width: 500px;
	margin: 80px 80px 80px 80px;
    /* height: 500px; */
	/* overflow: scroll; */
    border-radius: 10px;
    /* box-shadow: 1px 2px rgba(0, 0, 0, 0.1) */
    border: 2px solid rgba(0, 0, 0, 0.1)
}

.LocationRow{
	/* background-color: yellow; */
	width: 80px;

	/* height: 100%; */
	/* float: right; */
	/* align-content: flex-end; */
}

.FlagComp{
	color: black;
	margin-right: 30px;
	margin-bottom: 20px;
	padding: 0px 0px 0px 0px;
	display: inline;
	/* background-color: yellow; */
	/* width: fit-content; */
}

.LangBox{
	
	width: 100%;
	height: 200px;
	border-radius: 10px;
	border: 3px solid rgba(245, 122, 26, 0.97);
	display: flex;
	padding: 20px 20px 20px 20px;
	flex-wrap: wrap;
	overflow: scroll;
}
.LangHolder{
	text-align: center;
}
.LangName{
	/* margin: auto; */

	padding: 0px 0px 0px 0px;
	/* background-color: green; */
	text-align: center;
	/* min-width: inherit; */
	/* max-width: inherit; */
	width: 40px;
}

.CircleText{
	font-family: Muli;
	font-size: 60pt;
	/* font-size: 80px; */
	position: relative;
	font-weight: 500;
	/* background-color: green; */
	margin-top: 0px;
	/* align-items: center; */
	top: -5px;
	color: rgba(245, 122, 26, 0.97);
	/* bottom: auto; */
	
}
/* 
hr{
	border: 1px solid grey;
  	border-radius: 5px;
} */

.CircleCol{
	/* background-color: red;  */
	width: 100%;
	height: 100%;
	align-self: center;
	/* align-items: center; */
	text-align: center;
	padding-top: auto;
}

.PageHeadRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 960px;
	max-width: 960px;
	margin: 0 30px;
}

.Title {
	font-size: 25px;
	font-weight: bold;
	color: #3e3e3e;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.CreateButton {
	text-decoration: none;
	background-color: #08b60b;
	border: 1px solid #069108;
	color: #fff;
	padding: 7px 20px;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.16), inset 0px -2px 7px 0px rgba(0, 0, 0, 0.1),
		inset 0px 2px 0px 0px rgba(255, 255, 255, 0.3);
	font-size: 0.9em;
	user-select: none;
}

.CreateButton:hover {
	background-color: #10c010;
}

.Content {
	box-sizing: border-box;
	padding: 26px 0;
	min-width: 100%;
	background: transparent;
}

.ContainerMain {
	width: 960px;
	max-width: 100%;
	height: 100%;
	margin: auto;
	display: flex;
	flex-direction: row;
}

.RightColumn {
	height: 100%;
	width: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.CourseCard {
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid hsla(0,0%,93%,.25);
	border-radius: 10px;
	color: black;
	font-weight: bold;
	width: 400px;
	height: 150px;
	border-radius: 10px;
	margin: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	user-select: none;
	font-size: 1.1em;
	padding: 0 20px;
	background-color: #FFF4E0;
	border-radius: 20px;
	box-sizing: border-box;
}
.WotdCard {
width:400px;
height:200px;
border-radius: 50px;
background-color:#F0F9FF;
}

.CourseCard:hover {
	background: hsla(0,0%,100%,.15);
	border: 2px solid hsla(0,0%,100%,.5);
}


.CourseCardPlaceHolder {
	width: 200px;
	height: 100px;

	border: 1px solid #e4e4e4;
	border-radius: 10px;
	margin: 20px;
	box-sizing: border-box;

	animation-name: shine;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-direction: alternate;
}
.Content {
	box-sizing: border-box;
	padding: 62px 0;
	width: 100%;
	min-height: 100%;

	background-position: 0% 100%;
	background-size: 20% auto;
	background-repeat: no-repeat;
	background-color:white;
}

.WhiteBox {
	margin: 50px auto;
	margin-bottom: 70vh;
	width: 380px;
	max-width: 80%;
		background-color:white;
	border-radius: 10px;
	padding: 20px;
}


.LoginTitle {
	margin:auto;
	text-align:center;
	font-weight: bold;
	font-size: 1.5em;
	color: #3E3E3E;
	margin-bottom: 20px;
}

.Label {
	font-weight: bold;
	font-size: 0.9em;
	color: #3E3E3E;
	margin-top: 10px;
	margin-bottom: 5px;
}

.Input {
	width: 100%;
	font-size: 16px;
	height: 44px;
	border-radius: 6px;
	border: 1px solid #e4e4e4;
	box-sizing: border-box;
	padding: 11px 18px;
	color: #3E3E3E;
}

.LoginButton {
	margin: 15px 0;
	background-color: rgba(245, 122, 26, 0.97) ;
	color: white;
	border: 1px solid white;
	height: 47px;
	border-radius: 6px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	user-select: none;
}

.ForgotPassword{
	text-align: right !important;
	font-size: 0.6em;
	color:black;
	cursor:pointer;
}
.ForgotPassword:hover {
  color: lightblue;
}
.SignUpLink{

	font-size: 15px;
	cursor:pointer;
}
.SignUpLink:hover {
  color: lightblue;
}
.ErrorMessage {
	color: red;
	font-size: 0.8em;
}

.Error {
	border: 1px solid rgba(255,0,0,0.7);
}

@keyframes shine {
	from {
		transition: none;
	}
	to {
		background-color: #dedede;
		transition: all 0.3s ease-out;
	}
}

@media only screen and (max-width: 600px) {
	.Content {
		padding-top: 10px;
	}
	.CourseCard {
		width: 80%;
		margin: 10px 20px;
	}

	.Img {
		width: 40px;
		height: 40px;
	}
	.LangBox{
		/* margin:  0px 0px; */
		/* padding: 0px 0px 0px; */
	}

	
}

@media only screen and (max-width: 246px) {

	.Img {
		width: 40px;
		height: 40px;
	}
	.LangBox{
		margin-right: 0px;
		padding-right: 0px;
	}

	.FlagComp{
		/* margin-right: 10px; */
	}
}


@font-face{
    font-family: Muli;
    src:url(Mulish/Mulish-VariableFont_wght.ttf)
}

@font-face{
    font-family: Muli;
    src:url(Mulish/static/Mulish-Bold.ttf);
    font-weight: bold;
}
@font-face{
    font-family: Muli;
    src:url(Mulish/static/Mulish-ExtraBold.ttf);
    font-weight: 800;
}

@font-face{
    font-family: Muli;
    src:url(Mulish/static/Mulish-SemiBold.ttf);
    font-weight: 500;
}



@media only screen and (max-width: 700px){
	.body{
		padding: 80px 80px 80px 80px;
		width: 80vw;
		margin: auto;
		margin-top: 80px;
	}


}


@media only screen and (max-width: 500px){
	.body{
		padding: auto;
		width: 80vw;
		margin: auto;
		padding: 40px 40px 40px 40px;
		/* padding: 80px 80px 80px 80px; */
		margin-top: 80px;
	}


}