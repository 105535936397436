.Header {
	background: linear-gradient(180deg, rgba(2, 2, 2, 0.922) 0%, rgba(0, 0, 0, 0.97) 100%);
	top: 0;
	width: 100%;
	max-width: 100%;
	color: #000000;
	padding: 0;
	border: none;
	z-index: 10;
	height: 62px;
}


.HeaderRow {
	margin: auto;
	padding: 0 30px;
	width: 100%;
	max-width: 960px;
	height: 100%;
	display: flex;
	align-items: center;
}

.NavRow {
	margin: auto;
	width: 100%;
	height: 100%;

	align-items: center;
}

.LogoWrapper {
	width: 200px;
	min-width: 200px;
	align-items: center;
	display: flex;
	margin-top: 25px;
	margin-right: 10px;
	padding-left: 15px;
	background-image: url('../../../src/assets/images/white\ gradient\ png\ \(1\).png');
	background-position: 0% 100%;
	background-size: 100% auto;
	background-repeat: no-repeat;
	color: white;
	height: 50px;
	fill: white;
}

.NavButton {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	text-decoration: none;
	font-weight: 600;
	border-radius: 4px;
	position: relative;
	z-index: 100;
	vertical-align: middle;
	margin-right: 6px;
	height: 40px;
	padding: 0 18px;
}

.ActiveButton {
	background: rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.Invisible {
	display: none;
}

.SignUpPurple {
	background-color: #f8379c;
}

.LRMargin {
	margin: 0 20px;
}

.AuthNavButtonsDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-right: 30px;
	width: 100%;
}

.LogoutBtn {
	overflow: hidden;
	 white-space: nowrap;
	display: flex;
	align-items: center;
	color: white;
	text-decoration: none;
	font-weight: 600;
	border-radius: 4px;
	position: relative;
	z-index: 100;
	vertical-align: middle;
	margin-right: 6px;
	height: 40px;
	padding: 0 18px;
	min-width: 100px;
}

@media only screen and (max-width: 576px) {


	.AuthNavButtonsDiv{
		display: initial;
	}
}
