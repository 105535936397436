body{
    background-color: white;
}
.SpinnerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.first-skill-div {
    cursor: default;
    margin: 94px auto 0!important;
    max-width: 1140px;
    min-width: 900px;
}
.second-skill-div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.third-skill-div {
    height: 100%;
    min-height: 590px;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.skill-header-container {
    z-index: 200;
}
.skill-header-content-frame {
        height: 80px;
    padding-top: 40px;
        padding: 50px 40px 0;
            display: flex;
    height: 40px;
    margin: auto;
    max-width: 920px;
    position: relative;
}
.skill-x-button {
    left: 40px;
    position: absolute;
    background-position: -373px -153px;
    height: 18px;
    width: 18px;
    background-image: url(//d35aaqx5ub95lt.cloudfront.net/images/icon-sprite8.svg);
    display: inline-block;
    vertical-align: middle;
}
.skill-progress-button{
    flex-grow: 1;
}
.skill-progress-container-anon {
        margin: 0 0 0 40px;
    position: relative;
}
.skill-progress-container {
    border-radius: 98px;
    height: 16px;
        background: #e5e5e5;
    position: absolute;
    width: 100%;
}
.skill-progress-green {
    opacity: 0;
    width: 0%;
    background: #ffd900;
    min-width: 16px;
    position: relative;
    transition: all .5s;
    z-index: 1;
        border-radius: 98px;
    height: 16px;
}
.skill-progress-green::after {
    background: #fff;
    border-radius: 98px;
    content: "";
    display: block;
    height: 5px;
    margin: 0 10px 0 15px;
    opacity: .2;
    transform: translate3d(0,4px,0);
}

/* Body */
.skill-lesson-body{
    display: flex;
    font-size: 19px;
    text-align: center;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;
    /*************** using vh!!!! **************/
    /* height: 66vh; */
    /*************** using vh!!!! **************/
    width: 100%;
    opacity: 1;
    flex: 1;
}
.s-l-b-box {
    display: flex;
    /* height: calc(100vh - 232px); */
}

.challenge {
    transform: translateZ(0);
    width: 100%;
    margin: auto;
    -ms-flex-align: initial;
    align-items: initial;
    -ms-flex-pack: initial;
    justify-content: initial
}

.challenge-header {
        font-size: 25px;
    line-height: 24px;
        margin: 0;
    text-align: left;
    width: 100%;
}

.challenge-subbox{
        display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
}


.challenge-translate-prompt {
    -ms-flex-positive: 0;
    flex-grow: 0;
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: left;
    width: 100%;
}

.challenge-listen {
        background-color: #1bb0f6;
    color: #fff;
    border-width: 0 0 4px;
        border-color: #1799d6;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 13px 16px;
    font-size: 15px;
    line-height: 20px;
        display: inline-block;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
        cursor: pointer;
            border-radius: 16px;
    border-style: solid;
    touch-action: manipulation;
        transform: translateZ(0);
    transition: filter .2s;
            float: left;
    height: 40px;
    margin-right: 10px;
    width: 40px;
}
.c-l-s-d {
    height: 16px;
    margin: -100%;
    width: 21px;
    display: inline-block;
}



.challenge-sentence {
    color: #3c3c3c;
    font: 500 17px/20px sans-serif;
    line-height: 39px;
}
.challenge-prompt-text-container {
        display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-positive: 0;
    flex-grow: 0;
    height: 87px;
    margin-bottom: 24px;
    text-align: left;
    width: 100%;
}
.c-p-t-c-sub {
    flex-grow: 1;
    flex-direction: column;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
}
.challenge-textarea {
        resize: none;
    flex-grow: 1;
    -webkit-appearance: none;
    width: 100%;
    background-color: #f7f7f7;
    border-color: #e5e5e5;
    border-radius: 10px;
    padding: 10px 12px;
    line-height: 24px;
    border: 2px solid #e5e5e5;
    color: #3c3c3c;
    text-align: left;
        font-size: 100%;
}

/* Footer */

.skill-footer-container {
    z-index: 100;
}

.skill-footer-content-frame {
    bottom: 20px;
    transition: max-height .3s ease-in-out;
    width: 100%;
}

.skill-correct-container{
    box-sizing: border-box;
    background-color: #b8f28b;
    color: #58a700;
    bottom: 0;
    max-width: calc(100% - 150px - 40px);
    min-height: 140px;
    padding: 18px 0 18px 40px;
    position: relative;
    width: 100%;
    }
.skill-correct-content {
    display: flex;
}
.wrong-color {
    background-color: #ffc1c1;
    color: #ea2b2b;
}

.skill-correct-icon-div {
    display: none;
    animation-duration: .4s;
    animation-fill-mode: forwards;
    animation-name: a;
    animation-timing-function: ease-in-out;
}
.skill-correct-image {
    background-position: -166px -90px;
    height: 31px;
    width: 41px;
    background-image: url(//d35aaqx5ub95lt.cloudfront.net/images/icon-sprite8.svg);
    display: inline-block;
    vertical-align: middle;
}
.skill-incorrect-image {
    background-position: -208px -90px;
    width: 31px;
}
.correct-sub-content-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px;
}
.correct-h2-text {
    color: #58a700;
    margin: 0;
}
.incorrect-answer-div {
    font-size: 15px;
    font-weight: 500!important;
    margin-top: 5px;
}
.correct-bottom-content-container {
    margin-top: 20px;
    color: #58a700;

}
.crct-mini-image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    vertical-align: middle;
    width: 20px;
    /* color: #58a700; */
}
.mini-flag {
    background-image: url(//d35aaqx5ub95lt.cloudfront.net/images/grading-ribbon-flag-correct.svg);
}
.mini-i-flag {
    background-image: url(//d35aaqx5ub95lt.cloudfront.net/images/grading-ribbon-flag-incorrect.svg);
}
.mini-speech {
    background-image: url(//d35aaqx5ub95lt.cloudfront.net/images/grading-ribbon-discuss-correct.svg);
}
.mini-i-speech {
    background-image: url(//d35aaqx5ub95lt.cloudfront.net/images/grading-ribbon-discuss-incorrect.svg);
}
.crct-bottom-a {
    margin-right: 30px;
    /* color: #58a700; */
    display: inline-block;
    opacity: .7;
    padding: 0;
}
.crct-bottom-span{
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .8px;
    text-transform: uppercase;
    color: #58a700;

}
.skill-f-c-f {
    max-width: 1000px;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-touch-callout: none;
}
.skill-skip-button {

        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
}

.skill-f-bs {
    margin-bottom: 10px;
    min-width: 150px;
    width: 100%;
    background: none;
    color: #afafaf;
    border-width: 2px 2px 4px;
    border-color: #e5e5e5;
    padding: 12px 16px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 16px;
    border-style: solid;
    transform: translateZ(0);
    transition: filter .2s;
    font-weight: 700;
    letter-spacing: .8px;
    text-transform: uppercase;
    outline: none;
}
.skill-check-button {
    position: absolute;
    right: 40px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.s-c-b-correct {
    background-color: #78c800;
    color: #fff;
    border-color: #58a700;
    border-width: 0 0 4px;
}
.s-c-b-wrong {
    background-color: #ff4b4b;
    border-color: #ea2b2a;
}

.s-f-c-f-correct {
    background-color: #b8f28b;
    color: #58a700;
    transition: max-height .3s ease-in-out;
    width: 100%;
}
.wrong-color {
    background-color: #ffc1c1;
    color: #ea2b2b;
}


@media (min-width: 1000px) {
    .skill-f-c-f {
        margin-left: calc(50% - 500px);
    }
}

@media (min-width: 700px) {

    .challenge-translate {
        /* height: 66vh; */
        max-height: 350px;
        max-width: 600px;
        overflow: visible;
    }
    .challenge-header {
        font-size: 32px;
        line-height: 40px;
    }
    .challenge-translate-prompt {
        margin-bottom: 24px;
        margin-top: 24px;
    }
    .challenge-listen {
        margin-right: 15px;
    }
    .challenge-prompt-text-container {
        height: 170px;
    }
    .skill-f-c-f {
        min-height: 140px;
        width: 100%;
    }

    .skill-footer-content-frame{
        background: #fff;
        border-top: 2px solid #e5e5e5;
        max-height: 140px;
        min-height: 140px;
        overflow: hidden;
    }
    .s-f-c-f-correct {
        background-color: #b8f28b;
        color: #58a700;
        transition: max-height .3s ease-in-out;
        width: 100%;
    }
    .wrong-color {
        background-color: #ffc1c1;
        color: #ea2b2b;
    }
    .skill-correct-icon-div {
        display: block;
        background: #fff;
        border-radius: 98px;
        display: inline-block;
        float: left;
        height: 80px;
        margin-right: 16px;
        width: 80px;
    }
    .skill-correct-image {
        margin: 27px 0 0 20px;
    }
    .skill-incorrect-image {
        margin: 26px 0 0 25px;
    }
    .correct-sub-content-container {
        padding: 0;
    }
    .correct-h2-text {
        line-height: 30px;
        margin-bottom: 12px;
    }
    .wrong-color-text {
        color: #ea2b2b;
    }
    .incorrect-answer-div {
        font-size: 17px;
        margin-top: 0;
    }

    .skill-skip-button {
         left: 40px;
        /* -ms-transform: translateY(-50%);
        transform: translateY(-50%); */
    }
    .skill-f-bs {
        font-size: 17px!important;
    }
    .s-f-c-f-correct {
        min-height: 140px;
        overflow: hidden;
}
}



@media screen and (max-height: 500px) {
    .challenge-translate-prompt {
        margin-bottom: 8px;
        margin-top: 8px;
    }
    .s-f-c-f-correct {
        bottom: 20px;
    }
}

/* @media (max-height: 650px) and (min-width: 700px)
.Mlxjr {
    height: 80px;
    padding-top: 40px;
}
@media (min-width: 700px)
.Mlxjr {
    height: 100px;
    padding: 50px 40px 0;
} */
