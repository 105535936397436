.progressBar{
    position: relative;
    height: 15px;
    width: 350px;
    border-radius: 10px;
    border: 1px solid #afafaf;
}


.filler {
    background:radial-gradient(ellipse at center, #d7ffb8 0%,#58CC02 100%) ;
    height: 100%;
  
    border-radius: inherit;
    transition: width .2s ease-in;
}

/* 58CC02 */

/* radial-gradient(ellipse at center, #b5f088 0%,#58CC02 100%) */
