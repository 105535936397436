.ReconnectRow{
  margin-left:30vw;

}
.Wave{
  width:50vw;
  height:100vh;
}
.SecondHalf{
	text-align: center;
	min-height: "100vh";
  position: "relative";
	max-width: 100%;
}
