.Word{
    
}
.AnswerChoices {
    background-color:#00ff00;
}

.AnswerChoices:active {
    background-color:#ff00ff;
}

@media only screen and (max-width: 600px) {
	.Content {
		padding-top: 80px;
	}
	.ContainerMain {
		flex-direction: column;
		justify-content: center;
		align-content: center;
	}
	.RightColumn {
		margin: 10px;
	}
}

.CorrectAnswer{
    -moz-box-shadow: 0 0 3px #58CC02;
    -webkit-box-shadow: 0 0 3px #58CC02;
    box-shadow: 0 0 4px #58CC02;
}