.Back{
    height: 100vh;
    width: 100vw;
    padding-top: 80px;
    /* background: linear-gradient(180deg, rgba(2, 2, 2, 0.922) 0%, rgba(0, 0, 0, 0.97) 100%); */
}

.Circle{
    display: inline;
    border: 1px black;
    background-color: yellow;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
    overflow: hidden;

}

 .shine {
    width: 100vw;
    height: 100vh;
    /* border-radius: 10px; */
    background: #b4bcc5; 
    background-image: linear-gradient(to right, #b4bcc5 0%, #9da0b2 20%, #b4bcc5 40%, #b4bcc5 100%);
	/* edeef1 */
    background-repeat: no-repeat;  
    background-size: 250px 200px; 
    display: inline-block;
    position: relative; 
    animation-duration: 0.8s;
    animation-fill-mode: forwards; 
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
	position: relative;
	top: -5px;
	left: -5px
    }

span{
    position: relative;
    /* left: -30px; */
    /* background-color: red; */
}


@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }
    
    100% {
        background-position: 468px 0; 
    }
    }
    
      


.LanguageCard{
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid hsla(0,0%,93%,.25);
    border-radius: 10px;
    color: #fff;
    width: 250px;
    height: 200px;
    border-radius: 10px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    user-select: none;
    font-size: 1.1em;
    /* padding: 0 20px; */
    box-sizing: border-box;
    /* ................ */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    /* margin-right: 50px; */
    /* animation: slide 1s infinite; */
}


/* .LanguageCard:hover {
    background: hsla(0,0%,100%,.15);
    border: 2px solid hsla(0,0%,100%,.5);
	/* ................... */

/* } */ 

.ScaleAnimation{
	
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.ScaleAnimation:hover{
	-webkit-transform: scale(1.05, 1.05);
	transform: scale(1.05, 1.05);
}

.ScaleAnimation::after {
	border-radius: 5px;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	opacity: 0;
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }


  .ScaleAnimation:hover::after {
    opacity: 1;
} 
/* 
.box {
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
  
  border-radius: 5px;
  
}

.box::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box:hover {

}

.box:hover::after {
    opacity: 1;
}
 */

.LoadingBox {
	height: 200px;
	animation-name: shine;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-direction: alternate;
}

.NextUpLabel {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-size: 0.6em;
	font-weight: 600;
	margin-bottom: 2px;
}

.RightColumn {
	max-width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.SmallBlock{
    width: 90px;
    height: 6px;
	background-color: #f6f7f8;
    /* background-color: red;  */
	border-radius: 10px;
    overflow: hidden;
}

.BigBlock{
	/* background-color: red; */
    width: 90px;
    height: 11px;
    /* background-color: red; */
	/* border-top-left-radius: 10px border-top-right-radius, border-bottom-right-radius and border-bottom-left-radius */
	border-radius: 14px;
	/* border-top-left-radius: 20px; */
	background-color: #f6f7f8;
    margin-bottom: 11px;
    overflow: hidden;
}
